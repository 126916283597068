<template>
    <section>
      <top-alternative-section
        :image="topAlternative.image"
        :title="$t('aclaraciones.top.title')"
        :content="$t('aclaraciones.top.content')"
      ></top-alternative-section>

      <features-section
        :title="$t('aclaraciones.featuresSection.title')"
        :description="$t('aclaraciones.featuresSection.description')"
        :items="$t('aclaraciones.featuresSection.items')"
      ></features-section>
  
      <offers-section
        :items="$t('aclaraciones.offersSection.items')"
      ></offers-section>
  
      <contact-section></contact-section>
    </section>
  </template>
  
  <script>
  import TopAlternativeSection from "../sections/TopAlternativeSection.vue";
  import AclaracionImage from "../../assets/aclaracion.jpg";
  //import DescriptionSection from "../sections/DescriptionSection.vue";
  import OffersSection from "../sections/OffersSection.vue";
  import FeaturesSection from "../sections/FeaturesSection.vue";
  import ContactSection from "../sections/ContactSection.vue";
  
  export default {
    components: {
      TopAlternativeSection,
      //DescriptionSection,
      OffersSection,
      FeaturesSection,
      ContactSection
    },
    data() {
      return {
        AclaracionImage,
        topAlternative: {
          image: AclaracionImage,
          title: "Aclaraciones",
          content:
            "Orquestación y procesamiento de extremo a extremo en la gestión de controversias emisor y adquirente"
        },
        description: {
          title: "La solucion",
          content:
            "Los Emisores, hoy se enfrentan a una serie de obstáculos importantes, incluida la necesidad de recorrer un camino a través de nuevos canales de pago, y gestionar operaciones de pago con múltiples procesadores, múltiples productos y en distintas monedas. Todo esto, al tiempo que ofrece un servicio al cliente superior en un entorno altamente competitivo y cada vez más regulado"
        },
        features: {
          title: "Beneficios",
          description: "Description",
          items: [
            {
              title: "Eficiencia Operativa",
              icon: "mdi-clock-check-outline",
              text:
                "Entorno efectivo para levantar y dar seguimiento a solicitudes de aclaración en operativas Emisor y Adquirente."
            },
            {
              title: "Automatización",
              icon: "mdi-head-lightbulb",
              text:
                "Eliminación y/o Automatización de procesos manuales (Hojas de Excel)"
            },
            {
              icon: "mdi-cash-plus",
              title: "Reducción de Tiempos",
              text:
                "Reducción de tiempos en la ejecución de las tareas de conciliación."
            },
            {
              title: "Flexibilidad en Configuración",
              icon: "mdi-bell-alert-outline",
              text:
                "Flexibilidad en configuración de distintos escenarios: o	Cargos no reconocidos o	Rechazos por falta de autorización o	Validación de devoluciones o	Documentación soporte de la operación de una aclaración",
                
            },
            {
              title: "Generación de Pólizas",
              icon: "mdi-bell-alert-outline",
              text:
                "Registro contable y generación de pólizas de diario."
            }
          ]
        },
        offers: {
          items: [
          {
            icon: 'mdi-database-cog-outline',
            title: 'Supervisión',
            text:
              'Validación y supervisión de controversias en función a niveles de servicio.'
          },
          {
            icon: 'mdi-card-account-details-outline',
            title: 'Conciliaciones',
            text: 'Configuración de múltiples conciliaciones según cada operativa (PROSA, Visa, MasterCard, AMEX)'
          },
          {
            icon: 'mdi-file-chart-outline',
            title: 'Reportes',
            text:
              'Generación y envío a clientes de reportes derivados del proceso.'
          },
          {
            icon: 'mdi-format-line-spacing',
            title: 'Parametrización',
            text:
              'Parametrización de múltiples reglas y alertas operativas que se traducen en herramientas de diagnóstico, control y mejora continua.'
          },
          {
            icon: 'mdi-puzzle-outline',
            title: 'Microservicios',
            text:
              'Capacidades de extensión de Microservicios a Clientes y Afiliados.'
          }
        ]
        }
      };
    }
  };
  </script>
  